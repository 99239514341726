import { Button, Form, type FormProps, Input, Typography, Flex, Checkbox, Alert } from "antd";
import { useEffect, useState } from "react";
import { MdOutlineEmail } from "react-icons/md";
import { MdLockOutline } from "react-icons/md";
import useMessageToast from "../../hooks/error/errorMessage";
import { useLocation } from "react-router-dom";
import MicrosoftSvg from "../../assets/logo/microsoft-logo.svg";
import { FcGoogle } from "react-icons/fc";

const { Text } = Typography;

const HeadingStyle: React.CSSProperties = {
  fontSize: "24px",
  fontWeight: "700",
  lineHeight: "32px",
};

const subHeading: React.CSSProperties = {
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "28px",
  marginBottom: "10px",
};

const buttonStyle: React.CSSProperties = {
  color: "white",
  width: "100%",
  marginTop: "10px",
};

const formContainer: React.CSSProperties = {
  padding: "60px",
};

const forgetPasswordText: React.CSSProperties = {
  textAlign: "right",
};

type FieldType = {
  email: string;
  password: string;
};

const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const LoginForm = ({
  setModalState,
  login,
}: {
  setModalState: React.Dispatch<
    React.SetStateAction<{
      isLogin: boolean;
      isRegister: boolean;
      isForgotPassword: boolean;
      isOTP: boolean;
    }>
  >;
  login: (data: { email: string; password: string; return_to?: string; trustDevice?: boolean }) => Promise<void>;
}) => {
  const [form] = Form.useForm();
  const [clientReady, setClientReady] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const { contextHolder, success, error: errorMessage } = useMessageToast();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectUrl = searchParams.get("redirectUrl");
  const providerErrorParam = searchParams.get("error");
  const provider = searchParams.get("provider");

  const [providerError, setProviderError] = useState<string | null>(null);

  const [loginLoader, setLoginLoader] = useState(false);
  useEffect(() => {
    setClientReady(true);
  }, []);

  useEffect(() => {
    if (providerErrorParam && provider && providerErrorParam !== "" && provider !== "") {
      console.log("providerErrorParam", providerErrorParam);
      switch (providerErrorParam) {
        case "auth_failed":
          setProviderError("The authentication process was interrupted or failed. Please try again or contact support if the problem persists.");
          break;
        case "user_not_found":
          setProviderError("The specified email does not match any registered user. Please contact the administrator for further assistance.");
          break;
        default:
          setProviderError(providerErrorParam);
      }
    }
  }, []);

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    try {
      setLoginLoader(true);
      await login({
        email: values.email,
        password: values.password,
        trustDevice: rememberMe,
        return_to: redirectUrl ? redirectUrl : "",
      });
      setLoginLoader(false);
    } catch (error: any) {
      setLoginLoader(false);
      errorMessage(error.response.data.errors);
    }
  };

  return (
    <Flex style={formContainer} vertical={true}>
      {contextHolder}
      <Text style={HeadingStyle}>Sign In for dataphone</Text>
      <Text style={subHeading}>Sign In to access all the features Dataphone has to offer</Text>
      <Form name="basic" form={form} style={{ maxWidth: 600 }} initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item<FieldType>
          name="email"
          rules={[
            {
              type: "email",
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input prefix={<MdOutlineEmail className="site-form-item-icon" />} placeholder="Email Address or User Name" />
        </Form.Item>

        <Form.Item<FieldType> name="password" rules={[{ required: true, message: "Please input your password!" }]}>
          <Input.Password prefix={<MdLockOutline className="site-form-item-icon" />} placeholder="Password" />
        </Form.Item>
        <Checkbox onChange={(event) => setRememberMe(event.target.checked)}>Remember Me</Checkbox>
        <div style={forgetPasswordText}>
          {/* <a
            onClick={() =>
              setModalState({
                isForgotPassword: true,
                isLogin: false,
                isRegister: false,
                isOTP: false,
              })
            }
          >
            Forget Password?
          </a> */}
        </div>
        <Form.Item
          shouldUpdate
        >
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              loading={loginLoader}
              style={{
                ...buttonStyle,
                background: !clientReady || !form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length || loginLoader ? "#9997A5" : "#130D38",
              }}
              disabled={!clientReady || !form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length || loginLoader}
            >
              Log in
            </Button>
          )}
        </Form.Item>
      </Form>
      <form action={`${process.env.REACT_APP_AUTH_API}/auth/login?return_to=${redirectUrl ? redirectUrl : ""}`} method="POST" style={{ width: "100%" }}>
        <input type="hidden" name="provider" value="google" />
        <input type="hidden" name="trustDevice" value={rememberMe ? "true" : "false"} />
        <button type="submit" style={{ width: "100%", border: "none", background: "none" }}>
          <div style={{ display: "flex", marginBottom: "5px", alignItems: "center", justifyContent: "center" }}>
            <Button style={{ display: "flex", justifyContent: "center", width: "100%", gap: "10px", alignItems: "center" }}>
              <Flex align="center" gap={"10px"}>
                <FcGoogle />
                Sign in with Google
              </Flex>
            </Button>
          </div>
        </button>
      </form>
      <form action={`${process.env.REACT_APP_AUTH_API}/auth/login?return_to=${redirectUrl ? redirectUrl : ""}`} method="POST" style={{ width: "100%" }}>
        <input type="hidden" name="provider" value="microsoft" />
        <input type="hidden" name="trustDevice" value={rememberMe ? "true" : "false"} />
        <button type="submit" style={{ width: "100%", border: "none", background: "none" }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Button style={{ display: "flex", justifyContent: "center", width: "100%", gap: "10px", alignItems: "center" }}>
              <Flex align="center" gap={"10px"}>
                <img src={MicrosoftSvg} alt="microsoft" style={{ height: "15px", width: "15px" }} />
                Sign in with Microsoft
              </Flex>
            </Button>
          </div>
        </button>
      </form>
      {
        providerError && providerError !== "" && 
        <Alert
          style={{ marginTop: "10px" }}
          message={providerError}
          type="error"
        />
      }
    </Flex>
  );
};

export default LoginForm;
